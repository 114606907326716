.alert-info {
  --bs-alert-color: var(--info-400);
  --bs-alert-bg: var(--info-900);
  --bs-alert-border: var(--info-800);
}

.alert-hover {
  &:hover {
    filter: brightness(110%);

    cursor: pointer;
  }
}
